<template>
	<div class="single-view tool-json-beautifier">
		<div class="tool-box">
			<h1>{{ ii('JSON_BEAUTIFIER') }}</h1>
			<div>
				<div class="tool-input-box" :class="app.query.vertical ? 'vertical-view' : ''">
					<s-text-area
						v-model="json"
						ref="tf"
						placeholder="{}"
						:error="error"
					></s-text-area>
					<div class="result">
						<div v-html="html"></div>
						<span class="control-top">
							<s-copy-btn :text="copyJson"></s-copy-btn>
							<span
								:class="'link fa fa-toggle-' + (app.query.vertical ? 'right' : 'down')"
								@click="changeAlign()"
							></span>
						</span>
					</div>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

				<div class="examples-box">
					<h3>{{ ii('INPUT_EXAMPLES') }}:</h3>
					<ul>
						<li v-for="example in examples">
							<span class="link" @click="onExampleClick(example)">{{ example }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	@import "../styles/helpers";

	.tool-json-beautifier {
		.tool-input-box {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			max-width: 850px !important;

			& > div {
				margin: 5px;
				width: calc(50% - 10px);
			}

			.text-area textarea {
				.monospace();
				min-height: 150px;
				height: 100%;
			}

			.result {
				.monospace();
				position: relative;
				overflow-x: scroll;
				border: 1px solid #aaa;
				min-height: 150px;
				border-radius: 3px;
				padding: 2px 8px;

				.control-top {
					position: absolute;
					right: 7px;
					top: 5px;

					& > span {
						margin-left: 5px;
					}
				}

				div {
					font-size: 13px;
					margin: 0;

					.key {
						color: #4c78ab;
					}

					.value {
						color: green;
					}

					.number {
						color: coral;
					}

					.null {
						color: red;
					}

					.bool {
						color: blueviolet;
					}

					#show-hide-btn {
						position: relative;
						bottom: -2.5px;
						font-size: 14px;
						margin: 0 3px;
					}
				}
			}
		}

		.vertical-view {
			.text-area, .result {
				width: 100%;
				margin: 5px 0;
			}
		}
	}
</style>

<script>
	export default {
		data() {
			return {
				json: '',
				html: '',
				error: null,
				examples: [
					'{"key":"value"}',
					'{"arr":[{"float": -12.5},{"string":"value"}]}',
					'{"Doc":{"properties":{"added":{"type":"date"}}}}'
				]
			};
		},
		computed: {
			copyJson() {
				if (!this.json) return '';
				if (!this.parseJson(this.json)) return '';

				return this.beautifyJson(this.json);
			}
		},
		methods: {
			getHtml() {
				this.error = null;

				if (!this.json) return this.html = '';
				if (!this.parseJson(this.json)) {
					this.html = '';
					this.error = 'Invalid json';
					return;
				}

				let json = this.beautifyJson(this.json);
				let res = json.split('\n').map((line, index) => {
					let i = 0;
					line = line.replace(/ /g, '&nbsp;');
					line = line.replace(
						/""|"(.*?[^\\](\\\\)*?)"/g,
						(match, p1) => {
							++i;
							let escapedValue = (p1 || '')
								.replace(/</g, '&lt;')
								.replace(/>/g, '&gt;')
								.replace(/"/g, '&quot;')
								.replace(/'/g, '&#039;');
							return `<span class="${i === 1 ? 'key' : 'value'}">"${escapedValue}"</span>`
						}
					);

					if (line.indexOf('"') === -1) {
						line = line.replace(/([0-9\.\-]+)/, '<span class="number">$1</span>');
						line = line.replace(/null/, '<span class="null">null</span>');
						line = line.replace(/(true)|(false)/, '<span class="bool">$1$2</span>');
					}

					line = line.replace(/<\/span>:&nbsp;([0-9\.\-]+)/, '</span>:&nbsp;<span class="number">$1</span>');
					line = line.replace(/<\/span>:&nbsp;null/, '</span>:&nbsp;<span class="null">null</span>');
					line = line.replace(/<\/span>:&nbsp;(true)|<\/span>:&nbsp;(false)/, '</span>:&nbsp;<span class="bool">$1$2</span>');
					if (!line.length) return line;

					let lastChar = line.substring(line.length - 1);
					let lastTwoChars = line.substring(line.length - 2);
					let lastThreeChars = line.substring(line.length - 3);
					if (lastChar === '{' || lastChar === '[') {
						line += '<span class="link fa fa-minus-square-o" id="show-hide-btn" data-index="line' + index + '"></span><span id="line' + index + '">';
					} else if ((lastChar === '}' || lastChar === ']') && lastTwoChars !== '{}' && lastTwoChars !== '[]') {
						line = line.substring(0, line.length - 1) + '</span>' + lastChar
					} else if ((lastTwoChars === '},' || lastTwoChars === '],') && lastThreeChars !== '{},' && lastThreeChars !== '[],') {
						line = line.substring(0, line.length - 2) + '</span>' + lastTwoChars
					}

					return line;
				});

				this.html = res.join('<br>');
			},
			onExampleClick(example) {
				this.json = example;
				this.$refs.tf.focus();
			},
			collapseJsonAction(e) {
				if (!e.target || e.target.id !== 'show-hide-btn') return;

				let btn = e.target;
				let id = btn.dataset.index;
				let element = document.getElementById(id);

				if (element.style.display) {
					btn.className = 'link fa fa-minus-square-o';
					element.style.display = '';
				} else {
					btn.className = 'link fa fa-plus-square-o';
					element.style.display = 'none';
				}
			},
			changeAlign() {
				this.app.query.vertical = !this.app.query.vertical || '';
				this.refreshQuery();
			}
		},
		watch: {
			'json'() {
				clearTimeout(this.debounce);
				this.debounce = setTimeout(() => {
					this.getHtml();
					let json = this.json.length < 1000 ? this.json : '';
					if (this.app.query.json === json) return;

					this.app.query.json = json;
					this.refreshQuery();
					this.$nextTick(() => {
						this.$refs.tf.focus();
					});
				}, 300);
			},
			'app.query.json'() {
				if (!this.app.query.json) return;

				this.json = this.app.query.json;
			}
		},
		async mounted() {
			window.addEventListener('click', this.collapseJsonAction);

			let json;
			if (this.app.query.id) {
				json = await this.get('JSON.getJsonById', this.app.query.id);
			}
			if (!json && this.app.query.json) {
				json = this.app.query.json;
			}
			this.json = json || '';

			if (this.app.isMob) {
				this.editQueryParams({vertical: true});
			} else {
				this.$refs.tf.focus();
			}
		},
		destroyed() {
			window.removeEventListener('click', this.collapseJsonAction);
		}
	}
</script>
